import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import TextField from '@material-ui/core/TextField'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ToggleButton from '@material-ui/lab/ToggleButton'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import FlatbedTruck1 from '../images/flatbed-freight.jpg'
import RedpalletRev1 from '../images/ltl-freight.jpg'
import ParcelRev1 from '../images/parcel-package.jpg'
import PersonalRev from '../images/personal-freight.jpg'
import QuaterToFullRev1 from '../images/quarter-to-full-truckload.jpg'
import SuperBox from '../images/roadlinx-box-ship.jpg'



class ShippingRatesForm extends Component {

  constructor(props) {
    super(props)
    this.steps = [
      'Shipping Type',
      'Origin & Destination',
      'Shipping Details',
      'Complete Quote',
      'Success',
    ]
    this.shippingTypes = [
      {
        label: 'Personal Freight',
        subLabel:
          'We move most large pieces that can be easily shipped by truck. This includes motorized equipment & crates.',
        image: PersonalRev,
      },
      {
        label: 'LTL Freight',
        subLabel: 'This is any freight under 6 pallets or 10,000 lbs.',
        image: RedpalletRev1,
      },
      {
        label: 'Quarter to Full Truckload',
        subLabel:
          'This is any freight ranging from 6 Pallets to a Full Truckload.',
        image: QuaterToFullRev1,
      },
      {
        label: 'Flatbed Freight',
        subLabel:
          'We handle shipments on all types of flatbed and specialized equipment including oversize loads.',
        image: FlatbedTruck1,
      },
      {
        label: 'Parcel & Package',
        subLabel:
          "Under 100 lbs and less than 3' x 3' x 3' (i.e. can a person carry it?)",
        image: ParcelRev1,
      },
      {
        label: 'RoadLINX Box & Ship',
        subLabel:
          'Get free double-corrugate shipping boxes delivered to your home or business when you book your shipment with RoadLINX.',
        image: SuperBox,
      },
    ]
    this.locationTypes = [
      'Business with dock or forklift',
      'Business without dock or forklift',
      'Construction Site',
      'Convention center or trade show',
      'Residential',
    ]
    this.itemTypes = [
      'On Pallet(s)',
      'Crate(s)',
      'Boxes',
      'Bundles',
      'Drums',
      'Tubes/Pipes',
      'Other',
    ]
    this.state = this.getInitState()
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  getInitState = () => ({
    width: 0,
    activeStep: 0,
    status: 'init',
    formData: {
      shippingType: '',
      shippingFrom: {
        postalCode: '',
        locationType: '',
      },
      shippingTo: {
        postalCode: '',
        locationType: '',
      },
      items: [this.getItemInitState()],
      description: '',
      pickupDate: '',
      isStackable: false,
      isHazardous: false,
      personalInfo: {
        name: '',
        email: '',
        phone: '',
        company: '',
      },
    },
  })
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  getItemInitState = () => ({
    itemType: '',
    length: '',
    width: '',
    height: '',
    dimensionsUnit: 'in',
    weight: '',
    weightUnit: 'lbs',
    itemUnits: 1,
  })

  handleNext = e => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }))
    e.preventDefault()
  }

  handleBack = e => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }))
    e.preventDefault()
  }

  handleReset = e => {
    this.setState(() => this.getInitState())
    e.preventDefault()
  }

  addItem = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        items: prevState.formData.items.concat({
          ...this.getItemInitState(),
        }),
      },
    }))
  }

  removeItem = i => {
    this.setState(prevState => {
      const { items } = prevState.formData
      items.splice(i, 1)
      return { formData: { ...prevState.formData, items } }
    })
  }

  handleChange = e => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      this.setState(prevState => ({
        formData: { ...prevState.formData, [name]: checked },
      }))
    } else {
      if (name.includes('.')) {
        const [parent, child] = name.split('.')
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            [parent]: { ...prevState.formData[parent], [child]: value },
          },
        }))
      } else {
        this.setState(prevState => ({
          formData: { ...prevState.formData, [name]: value },
        }))
      }
    }
  }

  handleToggle = value => {
    if (value !== null) {
      this.setState(prevState => ({
        formData: { ...prevState.formData, shippingType: value },
      }))
    }
  }

  handleToggleFirst = value => {
    const {width} = this.state;
    if (value !== null) {
      this.setState(prevState => ({
        formData: { ...prevState.formData, shippingType: value },
      }))
      if(width < 768){
        setTimeout(() => this.setState(prevState => ({ activeStep: prevState.activeStep + 1 })), 500)
      }
    }
  }

  handleChangeInItem = (e, i) => {
    const { name, value } = e.target
    this.setState(prevState => {
      const { items } = prevState.formData
      items[i][name] = value
      return {
        formData: { ...prevState.formData, items },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ status: 'submitting' })

    const { formData: data } = this.state
    const formData = new FormData()
    formData.append('form-name', e.target.getAttribute('name'))
    let yoyoData = data

    var items = '';
    const items1 = yoyoData.items?.map((item) => (
      items += 'Item Type: ' + item.itemType + '\n Length: ' + item.length + '\n Width: ' + item.width + '\n Height: ' + item.height + '\n Dimensions Unit: ' + item.dimensionsUnit + '\n Weight: ' + item.weight + '\n Weight Unit: ' + item.weightUnit + '\n Item Units: ' + item.itemUnits + '\n \n'
    ))

    yoyoData.items = items

    for (const [key, value] of Object.entries(yoyoData)) {
      if (Array.isArray(value)) {
        for (const el of value) formData.append(`${key}[]`, JSON.stringify(el))
      } else if (typeof value === 'object') {
        for (let subKey in value)
          formData.append(`${key}.${subKey}`, value[subKey])
      } else formData.append(key, value)
    }


    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(res => {
        this.setState({ status: 'success' })
        //console.log(res)
      })
      .catch(err => {
        this.setState({ status: 'failed' })
        //console.log(err)
      })
  }

  render() {
    const { activeStep, status, formData: values } = this.state
    return (
      <Layout>
        <SEO title="Shipping Rates | RoadLinx Shipping Company" />
        <section className="credit-application-form">
          <Container fluid className="credit-application-form__header">
            <h1 className="credit-application-form__title">
              Shipping Rates Form
            </h1>
          </Container>
          <Container
            className="credit-application-form__container"
            fluid={false}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {this.steps.map(label => (
                <Step key={label}>
                  <StepLabel className="credit-application-form__heading">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <form
              name="Shipping Rates Form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              hidden
            >
              <input type="text" name="shippingType" />
              <input type="text" name="shippingFrom.postalCode" />
              <input type="text" name="shippingFrom.locationType" />
              <input type="text" name="shippingTo.postalCode" />
              <input type="text" name="shippingTo.locationType" />
              <input type="text" name="items[]" />
              <input type="text" name="description" />
              <input type="text" name="pickupDate" />
              <input type="checkbox" name="isStackable" />
              <input type="checkbox" name="isHazardous" />
              <input type="text" name="personalInfo.name" />
              <input type="text" name="personalInfo.email" />
              <input type="text" name="personalInfo.phone" />
              <input type="text" name="personalInfo.company" />
            </form>
            {(status === 'init' || status === 'submitting') && (
              <form
                name="Shipping Rates Form"
                method="POST"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="Shipping Rates Form"
                />
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={this.handleChange}
                />
                {activeStep === 0 && (
                  <>
                    <h3 className="mb-4 credit-application-form__mob-text-center">{this.steps[activeStep]}</h3>
                    <Row xl={3} lg={2} md={2} sm={1}>
                      {this.shippingTypes.map(
                        ({ image, label, subLabel }, i) => (
                          <Col key={i} xs={6} className="credit-application-form__custom-col">
                            <ToggleButton
                              selected={label === values.shippingType}
                              onChange={() => this.handleToggleFirst(label)}
                              value={label}
                              aria-label={label}
                              style={{ minHeight: '100%', width: '100%' }}
                            >
                              <div className="text-center text-capitalize">
                                <div className="mb-3">
                                  <img
                                    alt="thumbnail"
                                    className="credit-application-form__card-img"
                                    src={image}
                                  />
                                </div>
                                <div className="h4 mb-1 font-weight-bold credit-application-form__font-16">
                                  {label}
                                </div>
                              </div>
                            </ToggleButton>
                          </Col>
                        )
                      )}
                    </Row>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <h3 className="mb-3">Shipping From</h3>
                    <Row>
                      <Col md={4}>
                        <label>Enter Postal/Zip Code</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter Postal/Zip Code"
                          name="shippingFrom.postalCode"
                          value={values.shippingFrom.postalCode}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label>Pickup Location Type</label>
                        <Select
                          fullWidth
                          variant="outlined"
                          size="small"
                          margin="dense"
                          name="shippingFrom.locationType"
                          value={values.shippingFrom.locationType}
                          onChange={this.handleChange}
                        >
                          <MenuItem value="">--None--</MenuItem>
                          {this.locationTypes.map((loc, i) => (
                            <MenuItem value={loc} key={i} size="small">
                              {loc}
                            </MenuItem>
                          ))}
                        </Select>
                      </Col>
                    </Row>

                    <h3 className="mt-4 mb-3">Shipping To</h3>
                    <Row>
                      <Col md={4}>
                        <label>Enter Postal/Zip Code</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter Postal/Zip Code"
                          name="shippingTo.postalCode"
                          value={values.shippingTo.postalCode}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={4}>
                        <label>Delivery Location Type</label>
                        <Select
                          fullWidth
                          variant="outlined"
                          size="small"
                          margin="dense"
                          name="shippingTo.locationType"
                          value={values.shippingTo.locationType}
                          onChange={this.handleChange}
                        >
                          <MenuItem value="">--None--</MenuItem>
                          {this.locationTypes.map((loc, i) => (
                            <MenuItem value={loc} key={i}>
                              {loc}
                            </MenuItem>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <Row className="m-0 my-3">
                      <span className="h3 mr-3">Items</span>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        type="button"
                        onClick={this.addItem}
                      >
                        Add Item
                      </Button>
                    </Row>
                    {values.items.map((item, i) => (
                      <Row key={i}>
                        <Col md={1}>
                          <h5 className="p-2 py-5">Item {i + 1}.</h5>
                        </Col>
                        <Col md={2} className="px-2">
                          <label>Item Type</label>
                          <Select
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            name="itemType"
                            value={item.itemType}
                            onChange={e => this.handleChangeInItem(e, i)}
                          >
                            <MenuItem value="">--None--</MenuItem>
                            {this.itemTypes.map((type, i) => (
                              <MenuItem value={type} key={i}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </Col>
                        <Col md={1} className="px-2">
                          <label>Length</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Length"
                            name="length"
                            value={item.length}
                            onChange={e => this.handleChangeInItem(e, i)}
                          />
                        </Col>
                        <Col md={1} className="px-2">
                          <label>Width</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Width"
                            name="width"
                            value={item.width}
                            onChange={e => this.handleChangeInItem(e, i)}
                          />
                        </Col>
                        <Col md={1} className="px-2">
                          <label>Height</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Height"
                            name="height"
                            value={item.height}
                            onChange={e => this.handleChangeInItem(e, i)}
                          />
                        </Col>
                        <Col md={1} className="px-2">
                          <label>&nbsp;</label>
                          <Select
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            name="dimensionsUnit"
                            value={item.dimensionsUnit}
                            onChange={e => this.handleChangeInItem(e, i)}
                          >
                            {['in', 'ft'].map((val, i) => (
                              <MenuItem value={val} key={i}>
                                {val}
                              </MenuItem>
                            ))}
                          </Select>
                        </Col>
                        <Col md={2} className="px-2">
                          <label>Unit Weight</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Unit Weight"
                            name="weight"
                            value={item.weight}
                            onChange={e => this.handleChangeInItem(e, i)}
                          />
                        </Col>
                        <Col md={1} className="px-2">
                          <label>&nbsp;</label>
                          <Select
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            name="weightUnit"
                            value={item.weightUnit}
                            onChange={e => this.handleChangeInItem(e, i)}
                          >
                            {['lbs', 'kgs'].map((val, i) => (
                              <MenuItem value={val} key={i}>
                                {val}
                              </MenuItem>
                            ))}
                          </Select>
                        </Col>
                        <Col md={1} className="px-2">
                          <label>Units</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Units"
                            type="number"
                            name="itemUnits"
                            value={item.itemUnits}
                            onChange={e => this.handleChangeInItem(e, i)}
                          />
                        </Col>
                        <Col md={1} className="px-2">
                          <IconButton
                            className="my-3"
                            aria-label="delete"
                            color="secondary"
                            onClick={() => this.removeItem(i)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </Col>
                      </Row>
                    ))}

                    <Row className="mt-4">
                      <Col md={6}>
                        <label>Enter a brief product description</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter a brief product description"
                          name="description"
                          value={values.description}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={3}>
                        <label>Pickup Date</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="date"
                          name="pickupDate"
                          value={values.pickupDate}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={3}>
                        <div className="pt-4">
                          <FormControlLabel
                          className="MuiStepLabel-label"
                            control={
                              <Checkbox
                                checked={values.isStackable}
                                onChange={this.handleChange}
                                name="isStackable"
                              />
                            }
                            label="Stackable"
                          />
                          <FormControlLabel
                          className="MuiStepLabel-label"
                            control={
                              <Checkbox
                                checked={values.isHazardous}
                                onChange={this.handleChange}
                                name="isHazardous"
                              />
                            }
                            label="Hazardous"
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <h3 className="mb-3">Rates</h3>
                    <Row>
                      <Col md={3}>
                        <label>Full Name</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Full Name"
                          name="personalInfo.name"
                          value={values.personalInfo.name}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={3}>
                        <label>Email</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Email"
                          name="personalInfo.email"
                          value={values.personalInfo.email}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={3}>
                        <label>Phone</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Phone"
                          name="personalInfo.phone"
                          value={values.personalInfo.phone}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md={3}>
                        <label>Company Name</label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Company Name"
                          name="personalInfo.company"
                          value={values.personalInfo.company}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>

                    <Row className="m-0 mt-4">
                      <div className="credit-application-form__font-14">
                        By clicking submit button you agree to let RoadLINX
                        Communicate with you by email for the purpose of
                        providing freight rates offers and shipping related
                        communication.
                      </div>
                    </Row>
                  </>
                )}
                <Row className="m-0 mt-5">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    type="button"
                    className="mr-5"
                    onClick={this.handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className="mr-2"
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                  >
                    Back
                  </Button>
                  {activeStep === 3 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      type="submit"
                      disabled={status === 'submitting'}
                    >
                      {status === 'submitting' ? 'Submitting' : 'Submit'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      type="button"
                      onClick={this.handleNext}
                    >
                      Next
                    </Button>
                  )}
                </Row>
              </form>
            )}
            {status === 'success' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-success">
                  <CheckCircleIcon style={{ fontSize: 24 }} />
                </div>
                <div className="mb-4 credit-application-form__font-16">
                  Your form has been submitted successfully.
                </div>
              </div>
            )}
          </Container>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export default ShippingRatesForm
